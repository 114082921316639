<template>
  <div class="main-page-container gap-3">
    <el-row :gutter="20" class="h-full">
      <el-col :span="18">
        <!-- 
              ################################################################################# 
              Progress Count (Quotation, Order, Claim, Invoice)
              #################################################################################
              -->
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="flex flex-col h-full gap-3">
              <div class="bg-content h-full">
                <div class="labels">
                  <div class="text-2xl">The best assets of future industry are</div>
                  <div class="text-3xl">technology and people</div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="flex flex-wrap justify-between gap-3">
              <div v-for="cate of userSupport" :key="cate.id" class="cate w-1/2" :class="cate.id">
                <div class="cate-container flex justify-between align-center p-1">
                  <div class="cate-globe p-1 rounded-full flex justify-center align-center">
                    <Icon icon="mynaui:globe" width="26" height="26" class="text-white" />
                  </div>
                  <div class="cate-label text-lg">{{ cate.label }}</div>
                </div>
                <div
                  class="cate-count text-lg font-black cursor-pointer"
                  @click="handleGoPage(cate.id)"
                >
                  {{ cate.count !== '' ? Number(cate.count).toLocaleString() : '' }}
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="lastest-information round-box mt-3">
          <div class="flex justify-between title">
            <div class="text-[#202857] pt-[8px] text-lg font-black p-1">New E-BID</div>
            <div class="more-btn" @click="handleNewEbidMorePage">More +</div>
          </div>
          <!-- 
                ################################################################################# 
                New E-BID
                #################################################################################
                -->
          <div
            class="list-content bid pr-2"
            :style="
              newEBid['bidList'] && newEBid['bidList'].length > 0
                ? { 'align-items': 'flex-start', display: 'block' }
                : {}
            "
          >
            <div v-if="isLoadingNewEbid" class="loading">
              <Icon
                icon="line-md:loading-loop"
                width="36"
                height="36"
                class="text-[#202857] ml-2"
              />
            </div>
            <div
              v-if="newEBid['bidList'] && newEBid['bidList'].length > 0"
              style="align-items: flex-start; display: block"
            >
              <div v-for="item of newEBid['bidList']" :key="item.bidgNo" class="flex flex-row pt-1">
                <div
                  class="item hover:bg-gray-100 cursor-pointer items-center flex"
                  @dblclick="handleNewEbidItemClick(item)"
                >
                  <span class="w-[150px] text-left truncate mr-4">
                    <el-tag type="success" class="w-[150px]">{{ item.bidgCagyNm }}</el-tag>
                  </span>
                  <span class="text-left text-sm">{{ item.bidgNo }}</span>
                  <span class="w-[400px] text-left truncate ml-4">{{ item.bidgTtlNm }}</span>
                </div>
                <div class="flex flex-row justify-between items-center">
                  <span class="w-[10ch] text-left mt-1">{{ item.bidgStYmd }}</span>
                  <span> ~ </span>
                  <span class="w-[10ch] text-right mt-1">{{ item.bidgEndYmd }}</span>
                </div>
              </div>
            </div>
            <div v-else class="flex items-center justify-center h-full">
              <p style="color: #adaeb1">No items available.</p>
            </div>
          </div>
        </div>
        <div class="lastest-information round-box mt-3">
          <div class="flex justify-between">
            <!-- 
                ################################################################################# 
                Lastest Information Tabs 
                #################################################################################
               -->
            <div class="align-end !grow">
              <div class="flex justify-between title">
                <div class="text-[#202857] font-black text-lg p-1">Lastest Information</div>
                <div class="more-btn" @click="handleMorePage">More +</div>
              </div>
              <div class="p-3 pb-1">
                <div class="flex flex-wrap items-center border-b">
                  <el-button
                    v-for="tab of userSupport"
                    :key="tab"
                    class="tab-item-control"
                    @click="changeTab(tab.id)"
                  >
                    <div class="custom-tab-control" :class="{ action: activeTab == tab.id }">
                      {{ tab.label }}
                    </div>
                  </el-button>
                </div>
              </div>
            </div>
          </div>
          <!-- 
            ################################################################################# 
            Lastest Information Contents
            #################################################################################
            -->
          <div
            class="list-content last-info pr-2"
            :style="
              lastestInfo[activeTab] && lastestInfo[activeTab].length > 0
                ? { 'align-items': 'flex-start', display: 'block' }
                : {}
            "
          >
            <div v-if="isLoadingLastestInfo" class="loading">
              <Icon
                icon="line-md:loading-loop"
                width="36"
                height="36"
                class="text-[#202857] ml-2"
              />
            </div>
            <div
              v-if="lastestInfo[activeTab] && lastestInfo[activeTab].length > 0"
              class="p-1 pt-1"
              style="align-items: flex-start; display: block"
            >
              <div v-if="activeTab == 'quotation'">
                <div class="info-header flex justify-between">
                  <span>RFQ KIND</span>
                  <span>RFQ NO</span>
                  <span>VESSEL</span>
                  <span>START DATE</span>
                </div>
                <div
                  v-for="item of lastestInfo[activeTab]"
                  class="hover:bg-gray-100 cursor-pointer flex mt-2 mb-0"
                >
                  <div class="flex justify-between item" @dblclick="handleItemClick(item)">
                    <span>{{ item.kindNm }}</span>
                    <span>{{ item.rfqNo }}</span>
                    <span>{{ item.vslCd }}</span>
                    <span>{{ item.rfqStYmd }}</span>
                  </div>
                </div>
              </div>
              <div v-else-if="activeTab == 'order'">
                <div class="info-header flex justify-between">
                  <span>ODER KIND</span>
                  <span>PO NO</span>
                  <span>VESSEL</span>
                  <span>PO DATE</span>
                </div>
                <div
                  v-for="item of lastestInfo[activeTab]"
                  class="hover:bg-gray-100 cursor-pointer flex mt-2 mb-0"
                >
                  <div class="flex justify-between item" @dblclick="handleItemClick(item)">
                    <span>{{ item.kindNm }}</span>
                    <span>{{ item.purOrdNo }}</span>
                    <span>{{ item.vslCd }}</span>
                    <span>{{ item.purOrdYmd }}</span>
                  </div>
                </div>
              </div>
              <div v-else-if="activeTab == 'claim'">
                <div class="info-header flex justify-between">
                  <span>ODER KIND</span>
                  <span>PO NO</span>
                  <span>VESSEL</span>
                  <span>CLAIM NO</span>
                </div>
                <div
                  v-for="item of lastestInfo[activeTab]"
                  class="hover:bg-gray-100 cursor-pointer flex mt-2 mb-0"
                >
                  <div class="flex justify-between item" @dblclick="handleItemClick(item)">
                    <span>{{ item.kindNm }}</span>
                    <span>{{ item.purOrdNo }}</span>
                    <span>{{ item.vslCd }}</span>
                    <span>{{ item.clmNo }}</span>
                  </div>
                </div>
              </div>
              <div v-else-if="activeTab == 'invoice'">
                <div class="info-header flex justify-between">
                  <span>ODER KIND</span>
                  <span>PO NO</span>
                  <span>VESSEL</span>
                  <span>PO DATE</span>
                </div>
                <div
                  v-for="item of lastestInfo[activeTab]"
                  class="hover:bg-gray-100 cursor-pointer flex mt-2 mb-0"
                >
                  <div class="flex justify-between item" @dblclick="handleItemClick(item)">
                    <span>{{ item.kindNm }}</span>
                    <span>{{ item.purOrdNo }}</span>
                    <span>{{ item.vslCd }}</span>
                    <span>{{ item.purOrdYmd }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="flex items-center justify-center h-full">
              <p style="color: #adaeb1">No items available.</p>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="notice round-box py-4 px-3">
          <div class="flex justify-between title justify-start">
            <div class="text-[#202857] text-lg font-black p-1">Notice</div>
            <div class="more-btn" @click="handleNoticePage()">More +</div>
          </div>
          <div class="list-content flex pr-2">
            <!-- 
            ################################################################################# 
            Notice
            #################################################################################
            -->
            <div v-if="isLoading" class="loading">
              <Icon
                icon="line-md:loading-loop"
                width="36"
                height="36"
                class="text-[#202857] ml-2"
              />
            </div>
            <div v-for="item of notices" v-else class="flex p-1">
              <div
                class="hover:bg-gray-100 cursor-pointer flex justify-between item pl-2"
                @click="handleBoardRowDbClick(item)"
              >
                <span>{{ item.titleName }}</span>
                <span class="date">{{ formatDate(item.transmitYmd, 'yyyy-MM-dd') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="br-green flex flex-col gap-3">
          <div class="bg mt-3">
            <div class="border-b">
              <div class="flex gap-[10px] py-4 flex-col">
                <!-- 
              ################################################################################# 
              Support (신청서 작성방법, FAQ, Q&A, 사이버 제보실)
              #################################################################################
              -->
                <div class="flex cursor-pointer" @click="handleSupportPage('F1')">
                  <div class="w-[60px] h-[60px] bg-[#dadef4] flex justify-center align-center">
                    <img
                      src="@/assets/images/support-down.png"
                      :alt="$t('ewms.labels.downloadManual')"
                      class="bg-img"
                    />
                  </div>
                  <div class="ml-4 pt-1">
                    <div class="text-xs text-[#202857] pb-1">Support</div>
                    <div class="text-sm text-[#202857] font-extrabold">
                      {{ $t('common.labels.downloadManual') }}
                    </div>
                  </div>
                </div>
                <div class="flex cursor-pointer" @click="handleSupportPage('F2')">
                  <div class="w-[60px] h-[60px] bg-[#dadef4] flex justify-center align-center">
                    <img
                      src="@/assets/images/support-icon-1.png"
                      alt="Support FAQ"
                      class="bg-img"
                    />
                  </div>
                  <div class="ml-4 pt-1">
                    <div class="text-xs text-[#202857] pb-1">Support</div>
                    <div class="text-sm text-[#202857] font-extrabold">FAQ</div>
                  </div>
                </div>
                <div class="flex cursor-pointer" @click="handleSupportPage('F3')">
                  <div class="w-[60px] h-[60px] bg-[#dadef4] flex justify-center align-center">
                    <img src="@/assets/images/support-qna.png" alt="Support Q & A" class="bg-img" />
                  </div>
                  <div class="ml-4 pt-1">
                    <div class="text-xs text-[#202857] pb-1">Support</div>
                    <div class="text-sm text-[#202857] font-extrabold">Q & A</div>
                  </div>
                </div>
                <div class="flex cursor-pointer" @click="handleSupportPage('F4')">
                  <div class="w-[60px] h-[60px] bg-[#dadef4] flex justify-center align-center">
                    <img
                      src="@/assets/images/support-icon-3.png"
                      :alt="$t('ewms.labels.cyberReportingCenter')"
                      class="bg-img"
                    />
                  </div>
                  <div class="ml-4 pt-1">
                    <div class="text-xs text-[#202857] pb-1">Support</div>
                    <div class="text-sm text-[#202857] font-extrabold">
                      {{ $t('common.labels.cyberReportingCenter') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex gap-[8px] py-4 flex-col">
              <div class="flex items-center cursor-pointer" @click="handleSupportPage('F5')">
                <div class="w-[60px] h-[60px] bg-[#dadef4] flex justify-center align-center">
                  <img
                    src="@/assets/images/icon-link.png"
                    alt="vessel sechdeul"
                    class="bg-img"
                    style="width: 28px"
                  />
                </div>
                <div class="ml-4 pt-1">
                  <div class="text-sm text-[#202857] font-extrabold">Vessel Schedule</div>
                </div>
              </div>
              <div class="flex items-center cursor-pointer" @click="handleSupportPage('F6')">
                <div class="w-[60px] h-[60px] bg-[#dadef4] flex justify-center align-center">
                  <img
                    src="@/assets/images/icon-link.png"
                    alt="Delivery Document"
                    class="bg-img"
                    style="width: 28px"
                  />
                </div>
                <div class="ml-4 pt-1">
                  <div class="text-sm text-[#202857] font-extrabold">Delivery Document</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
  <!-- <div>
    <form id="hiddenForm" ref="hiddenForm" method="post" action="" style="display: none;">
      <input type="hidden" name="invoiceKind" :value="invoiceKind">
      <input type="hidden" name="vesselCode"  :value="vesselCode">
      <input type="hidden" name="supplierId"  :value="supplierId">
      <input type="hidden" name="poNos"       :value="poNo">
    </form>
  </div> -->
  <NoticeDetailPopup
    :data="boardRowSelected"
    :visible="isVisibleBoardDetail"
    @close="handleCloseBoardDetail"
  />
  <NoticePopup ref="noticePopupRef" />
</template>

<script setup lang="ts">
/**************************************************************************
 * import resource
 **************************************************************************/
import { onMounted, ref } from 'vue'
import { formatDate } from 'shared/utils'

import { CommonService } from '@/services/commons'
import { MODULE_CODES } from '@/shared/constants'
import { useMainStore } from '@/stores/main'
import { Bid, Board, LastestInfo, LastestItem, NewEBid } from '@/types'
import NoticeDetailPopup from '@/views/components/NoticeDetailPopup.vue'

// import mockupData from './mockup.json'

/**************************************************************************
 * Variable Definition
 **************************************************************************/
const boardRowSelected = ref<Board>()
const isVisibleBoardDetail = ref<boolean>(false)

const commonService = new CommonService()
const noticePopupRef = ref(null)
const isLoading = ref<boolean>(false)
const isLoadingLastestInfo = ref<boolean>(false)
const isLoadingNewEbid = ref<boolean>(false)
const activeTab = ref<string>('')
const notices = ref<Board[]>([])

// 초기화
const lastestInfo = ref<LastestInfo>({
  quotation: [],
  order: [],
  claim: [],
  invoice: [],
})
const newEBid = ref<NewEBid>({
  bidList: [],
})
const userSupport = ref([
  {
    id: 'quotation',
    label: 'Quotation',
    count: '',
  },
  {
    id: 'order',
    label: 'Order',
    count: '',
  },
  {
    id: 'claim',
    label: 'Claim',
    count: '',
  },
  {
    id: 'invoice',
    label: 'Invoice',
    count: '',
  },
])

const changeTab = (tabId: string) => {
  activeTab.value = tabId
}

/**************************************************************************
 * Event Handlers
 **************************************************************************/

const handleItemClick = (item: LastestItem) => {
  let url = ''
  if (activeTab.value === 'invoice') {
    url =
      '/procurement/e-supply-portal/e-purchase/invoice' +
      '?qInvoiceKind=' +
      item.kindCd +
      '&qSupplierId=' +
      item.supplierId +
      '&qVesselCode=' +
      item.vslCd +
      '&qPoNo=' +
      item.purOrdNo +
      ''
  } else if (activeTab.value === 'claim') {
    url =
      '/procurement/e-supply-portal/e-purchase/claimList' +
      '?qPoNo=' +
      item.purOrdNo +
      '&qClmNo=' +
      item.clmNo +
      '&qKindCd=' +
      item.kindCd +
      ''
  } else if (activeTab.value === 'order') {
    url = '/procurement/e-supply-portal/order' + ''
  } else if (activeTab.value === 'quotation') {
    url = '/procurement/e-supply-portal/quotation' + ''
  }

  console.log('url', url)

  if (url !== '') {
    window.location.href = url
  }
}

const handleNewEbidMorePage = () => {
  const url = '/epro/bid/supplier/bid-list'
  window.location.href = url
}

const handleMorePage = () => {
  let url = ''
  if (activeTab.value === 'quotation') {
    url = '/procurement/e-supply-portal/e-purchase/quotation'
  } else if (activeTab.value === 'order') {
    url = '/procurement/e-supply-portal/e-purchase/order'
  } else if (activeTab.value === 'claim') {
    url = '/procurement/e-supply-portal/e-purchase/claimList'
  } else if (activeTab.value === 'invoice') {
    url = '/procurement/e-supply-portal/e-purchase/invoice'
  }

  if (url !== '') {
    window.location.href = url
  }
}

const handleNewEbidItemClick = (item: Bid) => {
  const url = '/epro/bid/supplier/bid-list?bidNo=' + item.bidgNo + '&bidSeq=' + item.bidgAdmSeq + ''
  window.location.href = url
}

const handleGoPage = (pageType: string) => {
  let url = ''
  if (pageType === userSupport.value[0].id) {
    url = '/procurement/e-supply-portal/e-purchase/quotation'
  } else if (pageType === userSupport.value[1].id) {
    url = '/procurement/e-supply-portal/e-purchase/order'
  } else if (pageType === userSupport.value[2].id) {
    url = '/procurement/e-supply-portal/e-purchase/claimList'
  } else if (pageType === userSupport.value[3].id) {
    url = '/procurement/e-supply-portal/e-purchase/invoice'
  }
  if (url !== '') {
    window.location.href = url
  }
}

const handleSupportPage = async (pageType: string) => {
  let url = ''
  if (pageType === 'F1') {
    const mainStore = useMainStore()
    mainStore.showLoading()
    try {
      const { data } = await commonService.getAdminConfiguration(MODULE_CODES.EPRO)
      if (data?.contactVmsFileId) {
        const fileDetails = await commonService.getFiles(Number(data.systemManualVmsFileId))
        const file = fileDetails.data[0]
        await commonService.file(file, false, false, true)
      }
    } catch (error) {
      console.error('Error loading PDF:', error)
    } finally {
      mainStore.hideLoading()
    }
    return
  } else if (pageType === 'F2') {
    url = '/support/board/faq'
  } else if (pageType === 'F3') {
    url = '/support/board/qa'
  } else if (pageType === 'F4') {
    //url = 'https://www.hmm21.com/ethics/informantReg.do?lang=en'
    const userLanguage = ref<string>(navigator.language || 'en')
    url = 'https://www.hmm21.com/ethics/informantReg.do?lang=' + userLanguage.value
    window.open(url, '_blank')
    return
  } else if (pageType === 'F5') {
    url = 'http://cloud.hanil-fuji.com/index.php/s/yzEjcc9Drw3xRKA'
    window.open(url, '_blank')
    return
  } else if (pageType === 'F6') {
    url = 'http://cloud.hanil-fuji.com/index.php/s/DcXxkZ7qPpci2Pb'
    // url = 'http://scm.fuji-global.com'
    window.open(url, '_blank')
    return
  }

  if (url !== '') {
    window.location.href = url
  }
}

const handleNoticePage = () => {
  window.location.href = '/support/board/notice'
}
const handleShowBoardDetail = () => {
  isVisibleBoardDetail.value = true
}

const handleCloseBoardDetail = () => {
  isVisibleBoardDetail.value = false
}

const handleBoardRowDbClick = (item) => {
  boardRowSelected.value = item
  handleShowBoardDetail()
}

/**************************************************************************
 * Functions
 **************************************************************************/
const getBoardList = () => {
  isLoading.value = true
  const params = {
    boardType: 'NOTICE',
    moduleCode: MODULE_CODES.EPRO,
    page: 0,
    size: 5,
  }
  commonService
    .getNotices(params)
    .then((response) => {
      notices.value = response.data
    })
    .finally(() => {
      isLoading.value = false
    })
}

const getLastestInfoQuotation = () => {
  isLoadingLastestInfo.value = true
  const params = {
    page: 0,
    size: 5,
  }

  commonService
    .getLastestInfoQuotation(params)
    .then((response) => {
      lastestInfo.value.quotation = response.data.quotationList
    })
    .finally(() => {
      isLoadingLastestInfo.value = false
    })
}

const getLastestInfoOthers = () => {
  isLoadingLastestInfo.value = true
  const params = {
    page: 0,
    size: 5,
  }

  commonService
    .getLastestInfoOthers(params)
    .then((response) => {
      lastestInfo.value.order = response.data.orderList
      lastestInfo.value.claim = response.data.claimList
      lastestInfo.value.invoice = response.data.invoiceList
    })
    .finally(() => {
      isLoadingLastestInfo.value = false
    })
}

const getNewEbid = () => {
  isLoadingNewEbid.value = true
  const params = {
    page: 0,
    size: 5,
  }

  commonService
    .getNewEbid(params)
    .then((response) => {
      newEBid.value.bidList = response.data.bidList
    })
    .finally(() => {
      isLoadingNewEbid.value = false
    })
}

const getProgressCount = () => {
  //isLoading.value = true
  const params = {
    page: 0,
    size: 5,
  }

  commonService
    .getProgressCount(params)
    .then((response) => {
      userSupport.value[0].count = response.data.quotation.cnt
      userSupport.value[1].count = response.data.order.cnt
      userSupport.value[2].count = response.data.claim.cnt
      userSupport.value[3].count = response.data.invoice.cnt
    })
    .finally(() => {
      changeTab('quotation')
      //isLoading.value = false
    })
}

/**************************************************************************
 * Lifecycle Hooks
    onBeforeMount: 컴포넌트가 마운트되기 직전에 호출됩니다.
    onMounted: 컴포넌트가 마운트된 후에 호출됩니다.
    onBeforeUpdate: 컴포넌트가 업데이트되기 직전에 호출됩니다.
    onUpdated: 컴포넌트가 업데이트된 후에 호출됩니다.
    onBeforeUnmount: 컴포넌트가 언마운트되기 직전에 호출됩니다.
    onUnmounted: 컴포넌트가 언마운트된 후에 호출됩니다.
 **************************************************************************/
onMounted(() => {
  getBoardList()
  getLastestInfoQuotation()
  getLastestInfoOthers()
  getNewEbid()
  getProgressCount()
})
</script>

<style lang="scss">
.main-page-container {
  width: 100%;
  height: 100%;

  .bg-content {
    padding-top: 60px;
    background-size: cover;
    background-image: url('@/assets/images/HMM_Epro.png');
    background-position: center;
    border-radius: 5px;

    .labels {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
      opacity: 0.5;
      gap: 10px;
    }
  }

  .more-btn {
    margin-left: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #202857;
    font-weight: 1000;
  }

  .notice {
    display: flex;
    flex-direction: column;
  }

  .lastest-information {
    display: flex;
    flex-direction: column;

    .last-info {
      min-height: 205px;

      .item span {
        width: 20%;
        text-align: center;
      }
    }
    .bid {
      height: 160px;
    }
  }

  .notice {
    min-height: 240px;
  }

  .list-content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 5px 10px;

    .item {
      flex-grow: 1;
      color: #333;
      font-weight: 700;
    }
  }

  .cate {
    display: flex;
    flex-direction: column;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    padding: 20px;
    height: 115px;
    width: 48%;

    .cate-label {
      font-weight: 700;
    }

    .cate-count {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      font-size: 40px;
    }
  }

  .quotation {
    border-color: #7f9eda;
    color: #1d3c7a;
    background: url(@/assets/images/icon-progress-quotation.svg);
    background-repeat: no-repeat;
    background-position: 10px 50px;
    background-color: #b2c8f3;
  }

  .cate-globe {
    width: 30px;
    height: 30px;
    border-radius: 0;
  }

  .order {
    border-color: #80c6b3;
    color: #026a4d;
    background: url(@/assets/images/icon-progress-order.svg);
    background-repeat: no-repeat;
    background-position: 10px 50px;
    background-color: #afe6d7;
  }

  .cate-globe svg {
    display: none;
  }

  .claim {
    border-color: #ccb57e;
    color: #906b12;
    background: url(@/assets/images/icon-progress-claim.svg);
    background-repeat: no-repeat;
    background-position: 10px 50px;
    background-color: #e6d6af;
  }

  .invoice {
    border-color: #abba6d;
    color: #718b0c;
    background: url(@/assets/images/icon-progress-invoice.svg);
    background-repeat: no-repeat;
    background-position: 10px 50px;
    background-size: 15%;
    background-color: #d4e19e;
  }

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #cbcbcb;
    opacity: 0.2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .el-button:hover {
    background-color: transparent !important;
  }

  .tab-item-control {
    border: none;
    padding: 0;
    position: relative;
    font-size: 1rem;

    span {
      width: 100% !important;
    }

    .el-button + .el-button {
      margin-left: 0px;
    }

    .custom-tab-control {
      width: 100%;
      border: none;
      border-width: 1px;
      border-color: #cbcbcb;
      border-radius: 5px 5px 0 0;
      color: #5e5e5e;
      border-bottom: 1px solid #cbcbcb;
      padding: 12px 40px 7px 40px;
      letter-spacing: -0.5px;
      background: #ffffff;
    }

    .custom-tab-control.action {
      border-style: solid;
      border-color: #ee312f;
      color: #000000;
      font-weight: bold;
      background-color: #fff;
      box-shadow: inset 0 3px 0 #ee312f;
      border-bottom: 1px solid #fff;
    }

    .custom-tab-control.action::before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 7px solid #ec3834;
      top: -1px;
      left: 50%;
      margin-left: -4px;
    }

    button:has(.custom-tab-control.action) + button:has(.custom-tab-control) {
      margin-left: 0px;
    }
    .tab-item-control:hover {
      border: none;
    }
    .tab-item-control .custom-tab-control:hover {
      color: #0773fc;
    }
  }
}
.br-green:has(.bg) {
  height: 520px;
}
.round-box {
  padding: 0px;
  border-radius: 5px;
  border: 1px solid #c7cede;

  .title {
    background: #eff4ff;
    padding: 10px;
    padding: 0px 10px;
  }
}

.border-b {
  border-bottom: 1px solid #cbcbcb;
}

.border-b:has(.tab-item-control) {
  height: 34px;
}

.bg {
  background: #ffffff;
  border: 1px solid #c7cede;
  border-radius: 5px;
  padding: 0 15px;
}
.br-green > .bg {
  height: 560px;
}

.info-header {
  background: #dde0f3;
  padding: 7px 5px;

  span {
    width: 20%;
    text-align: center;
  }
}
.login-greeting-text h1 {
  text-shadow: 0px 4px 8px #292929;
}
</style>
